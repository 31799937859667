<template>
  <div id="TokenPriceDetail" class="card">
    <div class="card-body">
      <h2 class="h5">{{ tokenDetail.name }}  <span class="text-muted h6">{{ tokenDetail.symbol }}</span></h2>
      <h3  v-if="tokenDetail.price_usd" class="d-flex align-items-center h2">
        <span class="" v-html="$fixPrice(tokenDetail.price_usd,this.decimals)"></span>€
        <span v-if="tokenDetail.variation24h" class="h6 mb-0 ml-2" :class="[{'text-danger':tokenDetail.variation24h < 0},{'text-success':tokenDetail.variation24h >= 0}]">{{$fixPrice(tokenDetail.variation24h, 2)}}%(1d)</span></h3>
      <dl class="row font-weight-bold mb-0 font-size-085">
        <dt class="col-6 text-muted" v-if="tokenDetail.creationTime">Fecha creación</dt>
        <dd class="col-6 text-right" v-if="tokenDetail.creationTime">{{ $formatDate(tokenDetail.creationTime) }}</dd>
        <dt class="col-6 text-muted" v-if="tokenDetail.price1h">Precio 1h</dt>
        <dd class="col-6 text-right" v-if="tokenDetail.price1h">
          <span class="h6 mb-0 mr-2 font-size-075 font-weight-bold" :class="[{'text-danger':tokenDetail.variation1h < 0},{'text-success':tokenDetail.variation1h >= 0}]">{{$fixPrice(tokenDetail.variation1h, 2)}}%</span>
          <span v-html="$fixPrice(tokenDetail.price1h,this.decimals)"></span>€
        </dd>
        <dt class="col-6 text-muted" v-if="tokenDetail.price6h">Precio 6h</dt>
        <dd class="col-6 text-right" v-if="tokenDetail.price6h">
          <span class="h6 mb-0 mr-2 font-size-075 font-weight-bold" :class="[{'text-danger':tokenDetail.variation6h < 0},{'text-success':tokenDetail.variation6h >= 0}]">{{$fixPrice(tokenDetail.variation6h, 2)}}%</span>
          <span v-html="$fixPrice(tokenDetail.price6h,this.decimals)"></span>€
        </dd>
        <dt class="col-6 text-muted" v-if="tokenDetail.price24h">Precio 24h</dt>
        <dd class="col-6 text-right" v-if="tokenDetail.price24h">
          <span class="h6 mb-0 mr-2 font-size-075 font-weight-bold" :class="[{'text-danger':tokenDetail.variation24h < 0},{'text-success':tokenDetail.variation24h >= 0}]">{{$fixPrice(tokenDetail.variation24h, 2)}}%</span>
          <span v-html="$fixPrice(tokenDetail.price24h,this.decimals)"></span>€

        </dd>
        <dt class="col-6 text-muted" v-if="tokenDetail.volume_usd.h24">Volume 24h</dt>
        <dd class="col-6 text-right" v-if="tokenDetail.volume_usd.h24">{{ $formatRoundNumber(tokenDetail.volume_usd.h24) }}</dd>
        <dt class="col-6 text-muted" v-if="tokenDetail.decimals">Decimals</dt>
        <dd class="col-6 text-right" v-if="tokenDetail.decimals">{{ $formatRoundNumber(tokenDetail.decimals) }}</dd>

        <dt class="col-6 text-muted" v-if="tokenDetail.total_supply">Total supply</dt>
        <dd class="col-6 text-right" v-if="tokenDetail.total_supply">{{ $formatRoundNumber(tokenDetail.total_supply) }}</dd>
        <dt class="col-6 text-muted" v-if="tokenDetail.circulatingSupply">Circulating supply</dt>
        <dd class="col-6 text-right" v-if="tokenDetail.circulatingSupply">{{ $formatRoundNumber(tokenDetail.circulatingSupply) }}</dd>
        <dt class="col-6 text-muted" v-if="tokenDetail.market_cap_usd">Market Cap</dt>
        <dd class="col-6 text-right" v-if="tokenDetail.market_cap_usd">{{ $formatRoundNumber(tokenDetail.market_cap_usd) }}€</dd>
        <dt class="col-6 text-muted" v-if="tokenDetail.fdv_usd">Fully Diluted Value</dt>
        <dd class="col-6 text-right" v-if="tokenDetail.fdv_usd">{{ $formatRoundNumber(tokenDetail.fdv_usd) }}€</dd>
        <dt class="col-6 text-muted" v-if="tokenDetail.holders">Holders</dt>
        <dd class="col-6 text-right mb-0" v-if="tokenDetail.holders">{{ $formatRoundNumber(tokenDetail.holders) }}</dd>
      </dl>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'TokenPriceDetail',
  data() {
    return {
      address: this.$route.params.address,
      chain: this.$route.params.chain || 'bsc',
      tokenDetail: '',
      decimals: ''
    };
  },
  created() {
    axios.get(`/v2/tokens/info?chain=${this.chain}&address=${this.address}`)
      .then((response)=> {
        this.tokenDetail = response.data.body.attributes;
        if(this.tokenDetail.price > 1){
          this.decimals = 2;
        }
        else{
          this.decimals = 4;
        }
        this.$emit('widgetLoaded');
      }); 
  },
  methods: {
  }
};
</script>

<style lang="scss">
</style>
